<template lang="pug">
    div.modules-row(@click="goto()" v-bind:class="{active: isActive}")
        button.button.side-menu.shadow(:class="isActive ? 'active' : '' ")
            img(:src="urlIcon")
        p(:class="{'text-averta-regular-20': !isActive, 'text-averta-bold-20': isActive}") {{ $t(label) }}
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({

    props: ["urlIcon", "label", "link", "isActive"],

    methods: {
        goto() {
            switch (typeof this.link) {
                case "string":
                    this.$router.push(this.link).catch(() => {
                    });
                    break;
                case "object":
                    if (this.link.cb) {
                        this.link.cb()
                    } else {
                        this.$router.push(this.link)
                    }
                    break;
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.modules-row {
    display: inline-flex;
    width: 180px;
    padding: 0px 17.5px;
    cursor: pointer;

    &:hover {
        p {
            color: var(--primary-color);
        }

        button {
            img {
                filter: var(--svg-filter-primary-color);
            }
        }
    }

    p {
        color: var(--black-color);
        padding-left: 25px;
        margin-top: 15px;
        z-index: 1;
        transition: color .25s;
    }

    &.active {
        p {
            color: var(--primary-color);
            font-weight: bolder;
        }

        &:hover {
            button {
                img {
                    filter: var(--svg-filter-white-color);
                }
            }
        }
    }
}
</style>